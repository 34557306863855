import styled, { css } from 'styled-components';
import { gold, grey } from '../../constants/theme';

export const Container = styled.div`
  background-color: #e9e2e1;
  padding-bottom: 50px;
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 30px;
  margin: 0 auto;
  display: flex;

  ${({ buy }) =>
    buy &&
    css`
      margin: 50px auto;

      @media (max-width: 700px) {
        flex-direction: column;
      }
    `}

  ${({ video }) =>
    video &&
    css`
      padding: 0 30px;
      margin-top: 50px;

      @media (max-width: 700px) {
        margin-top: 30px;
      }
      > div {
        border: 3px solid #fff;
        background-color: #d8d8d8;
        color: #fff;
        font-family: 'Halyard';
        text-transform: uppercase;
        box-shadow: 0 5px 15px #b0a6a2;
      }
    `}
`;

export const VideoBlock = styled.div`
  border: 3px solid #fff;
  background-color: #d8d8d8;
  color: #fff;
  font-family: 'Halyard';
  text-transform: uppercase;
  box-shadow: 0 5px 15px #b0a6a2;
  width: calc(100% - 200px);
  padding-bottom: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  margin: 0 auto;
`;

export const Col = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({ copy }) =>
    copy &&
    css`
      @media (max-width: 700px) {
        height: 100vw;
      }
    `}
`;

export const Title = styled.div`
  font-family: 'Halyard';
  color: ${gold};
  text-transform: uppercase;
  font-size: 2.2em;
  margin-bottom: 5px;
`;

export const PromoTitle = styled(Title)`
  color: #000;
`;

export const Buy = styled.a`
  background-color: #fff;
  color: ${gold};
  margin-bottom: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  line-height: 2.5;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 0 60px;
  white-space: nowrap;
  text-decoration: none;
  transition: all 130ms ease-out;

  &:hover {
    background-color: ${gold};
    color: #fff;
  }
`;

export const BuyBtns = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const AlbumCover = styled.img`
  width: calc(100% - 50px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
`;

export const PromoText = styled.div`
  text-align: center;
  width: 50%;

  @media (max-width: 700px) {
    width: 100%;
  }

  p {
    color: ${grey};
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 0.95em;

    strong {
      font-weight: 600;
    }
  }
`;
