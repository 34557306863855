import React from 'react';
import AlbumImg from '../../../static/album.png';
import YouTube from 'react-youtube-embed';
import {
  Container,
  Col,
  Wrapper,
  Title,
  PromoTitle,
  Buy,
  BuyBtns,
  AlbumCover,
  PromoText,
} from './promo.css';

const Promo = () => (
  <Container>
    <Wrapper>
      <PromoText>
        <p>
          Ahead of the release of our new album,{' '}
          <strong>‘Stuffed & Ready’</strong>, we’d like to give you the chance
          to hear another song before we unleash the full thing upon the world.
        </p>
        <p>But… there’s a twist: To hear it, you’ve gotta perform it!</p>

        <p>
          Simply download the guitar tabs and backing track above and film
          yourself playing along. Either follow the tab, or just show us your
          best licks, it’s up to you. Once you’re done, upload to your Instagram
          profile, remembering to tag <strong>#cherryglazed</strong> while
          making sure to also submit using the button below. Our favorite
          submissions will be compiled in a best-of video, and the best
          submission will win a Fender Player Series Stratocaster and a gig bag!
          So show us your skills…it could be you!
        </p>

        <p>Contest ends at 6pm EST on February 8th.</p>
      </PromoText>
    </Wrapper>

    <Wrapper video>
      <YouTube id="itx1_jGFtb0" />
    </Wrapper>

    <Wrapper buy>
      <Col>
        <AlbumCover src={AlbumImg} alt="album cover" />
      </Col>
      <Col copy>
        <Title>STUFFED & READY</Title>
        <PromoTitle>AVAILABLE FEBRUARY 2019</PromoTitle>

        <BuyBtns>
          <Buy
            href="https://cherryglazerr.ffm.to/stuffedandready"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRE-ORDER CD
          </Buy>
          <Buy
            href="https://cherryglazerr.ffm.to/stuffedandready"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRE-ORDER LP
          </Buy>
          <Buy
            href="https://cherryglazerr.ffm.to/stuffedandready"
            target="_blank"
            rel="noopener noreferrer"
          >
            PRE-ORDER Digital
          </Buy>
        </BuyBtns>
      </Col>
    </Wrapper>
  </Container>
);

export default Promo;
