import styled from 'styled-components';
import LogoImg from '../../../static/logo.png';

export const Container = styled.footer`
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.div`
  width: 93px;
  height: 77px;
  background: transparent url(${LogoImg}) no-repeat;
  background-size: 93px 77px;
`;

export const FinePrint = styled.p`
  margin-top: 15px;
  color: #524f54;
  font-size: 0.6em;
  text-transform: uppercase;
`;
