import React, { Component, Fragment } from 'react';
import axios from 'axios';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-modal';
import { Line } from 'rc-progress';
import XIcon from './closeX';
import {
  CloseBtn,
  FormContainer,
  FormHeading,
  Label,
  LoaderWrapper,
} from './upload-modal.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 0,
    width: '94%',
    maxWidth: '600px',
    boxSizing: 'border-box',
    backgroundColor: '#95894e',
    boxShadow: '0 5px 15px rgba(0,0,0,.25)',
    paddingBottom: '35px',
    position: 'relative',
  },
};

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  policyAgreement: Yup.boolean().oneOf([true], 'Must Accept Terms'),
});

const API = {
  getUrl: 'https://5zpcx4d528.execute-api.us-east-1.amazonaws.com/prod/upload',
  submitForm:
    'https://5zpcx4d528.execute-api.us-east-1.amazonaws.com/prod/submit',
};

Modal.setAppElement('#___gatsby');

class UploadModal extends Component {
  static propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  state = {
    finished: false,
    uploadProgress: null,
  };

  toggleFinished = () => {
    this.setState({ finished: true });
  };

  updateProgress = percent => {
    this.setState({ uploadProgress: percent });
  };

  render() {
    const { modalIsOpen, closeModal } = this.props;
    const { finished, uploadProgress } = this.state;
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          closeModal();
        }}
        style={customStyles}
        contentLabel="Upload your cover"
      >
        <CloseBtn onClick={closeModal}>
          <XIcon />
        </CloseBtn>
        {finished ? (
          <FormContainer thanks>
            <FormHeading>Thank You</FormHeading>
          </FormContainer>
        ) : (
          <Formik
            initialValues={{
              name: '',
              email: '',
              uploadLink: '',
              assetUrl: '',
              file: null,
              addToMailingList: false,
              policyAgreement: false,
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values, actions) => {
              let uniqueId;

              if (values.file) {
                uniqueId = cuid();

                const s3Url = await axios.post(API.getUrl, {
                  filename: uniqueId + values.file.name,
                  filetype: values.file.type,
                });

                const signedUrl = s3Url.data.url;
                const options = {
                  headers: {
                    'Content-Type': values.file.type,
                  },
                  onUploadProgress: progressEvent => {
                    let percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.updateProgress(percentCompleted);
                  },
                };

                let data = new FormData();
                data.append('file', values.file);

                await axios.put(signedUrl, values.file, options);
              }

              // eslint-disable-next-line
              const {
                name,
                email,
                uploadLink,
                addToMailingList,
                policyAgreement,
              } = values;

              let s3Url = values.file
                ? 'https://s3.amazonaws.com/shreddy-guitar/' +
                  uniqueId +
                  values.file.name
                : '';

              try {
                await axios.post(API.submitForm, {
                  name,
                  email,
                  uploadLink,
                  assetUrl: s3Url,
                  addToMailingList,
                  policyAgreement,
                });
              } finally {
                actions.resetForm();
                this.toggleFinished();
              }
            }}
            render={({ isSubmitting, setFieldValue }) => (
              <Form>
                <FormContainer>
                  <FormHeading>Your Cover</FormHeading>
                  {/* eslint-disable */}
                  <div className="formGroup formGroup--half">
                    <Label htmlFor="name">
                      <div>
                        name*{' '}
                        <ErrorMessage
                          name="name"
                          render={msg => <span>{msg}</span>}
                        />
                      </div>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Your name"
                        id="name"
                      />
                    </Label>
                    <Label htmlFor="email">
                      <div>
                        email*{' '}
                        <ErrorMessage
                          name="email"
                          render={msg => <span>{msg}</span>}
                        />
                      </div>
                      <Field
                        name="email"
                        type="text"
                        placeholder="example@gmail.com"
                        id="email"
                      />
                    </Label>
                  </div>
                  <div className="formGroup">
                    <Label htmlFor="uploadLink">
                      <div>Submission Url</div>
                      <Field
                        name="uploadLink"
                        type="text"
                        placeholder="https://instagram.com/username/post"
                        id="uploadLink"
                      />
                    </Label>
                  </div>

                  <div className="formGroup">
                    <Label
                      htmlFor="file"
                      style={{
                        marginBottom: '5px',
                        position: 'relative',
                        left: '-5px',
                      }}
                    >
                      Upload Submission
                      <input
                        name="file"
                        type="file"
                        id="file"
                        accept="audio/*, video/*"
                        title="No file chosen"
                        onChange={e => {
                          setFieldValue('file', e.target.files[0]);
                        }}
                        style={{
                          width: '100% !important',
                          fontSize: '.9em',
                        }}
                      />
                    </Label>
                  </div>

                  <div className="formGroup inputCheck">
                    <Label htmlFor="addToMailingList">
                      <Field
                        name="addToMailingList"
                        type="checkbox"
                        id="addToMailingList"
                      />
                      <div>Join Cherry Glazerr mailing list</div>
                    </Label>
                  </div>

                  <div className="formGroup inputCheck">
                    <Label htmlFor="polictyAgreement">
                      <Field
                        name="policyAgreement"
                        type="checkbox"
                        id="polictyAgreement"
                      />
                      <div>
                        Agree to our{' '}
                        <a
                          href="https://s3.amazonaws.com/shreddy-guitar/SC-FenderContestRules-CherryGlazerr-KL12419.pdf"
                          target="_blank"
                        >
                          terms and conditions
                        </a>{' '}
                        <ErrorMessage
                          name="policyAgreement"
                          render={msg => (
                            <span style={{ color: 'black' }}>{msg}</span>
                          )}
                        />
                      </div>
                    </Label>
                  </div>

                  {isSubmitting && uploadProgress && (
                    <Line strokeColor="#524f54" percent={uploadProgress} />
                  )}

                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <LoaderWrapper>
                        <Fragment>Submitting...</Fragment>
                      </LoaderWrapper>
                    ) : (
                      <Fragment>Submit Cover</Fragment>
                    )}
                  </button>
                </FormContainer>
              </Form>
            )}
          />
        )}
      </Modal>
    );
  }
}

export default UploadModal;
