import React, { Component } from 'react';
import {
  Container,
  Title,
  ContestName,
  Details,
  Copy,
  BtnsGroup,
  Btn,
  ClemWrapper,
  BtnLink,
} from './hero.css';
import UploadModal from '../upload-modal';
import FenderLogo from './fenderLogo';
import Clem from '../../../static/clem.png';
import { Col } from '../../global.css';

class Hero extends Component {
  state = {
    modalIsOpen: false,
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return (
      <Container>
        <Col>
          <Copy>
            <p className="gifting">Gifting Provided By*</p>
            <FenderLogo />
            <Title />
            <ContestName>
              Guitar
              <br />
              Contest
            </ContestName>
            <Details>
              Download the tab, film yourself playing along, upload and
              Instagram it using <strong>#cherryglazed</strong>… win a guitar or
              a signed LP bundle!
            </Details>

            <BtnsGroup>
              <BtnLink
                href="https://s3.amazonaws.com/shreddy-guitar/shreddy_resources.zip"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Tabs + Audio
              </BtnLink>
              <Btn onClick={this.openModal}>Upload Cover</Btn>
            </BtnsGroup>
          </Copy>
        </Col>
        <Col clem>
          <ClemWrapper>
            <img src={Clem} alt="Cherry Glazerr" />
          </ClemWrapper>
        </Col>
        <UploadModal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
        />
      </Container>
    );
  }
}

export default Hero;
