import styled from 'styled-components';
import { gold } from '../../constants/theme';

export const Container = styled.div`
  background-color: #f9f9f9;
  width: 100%;
  padding: 10px 10px 30px;
  text-align: center;
`;

export const Title = styled.div`
  color: ${gold};
  text-transform: uppercase;
  font-family: 'Halyard';
  font-size: 2.4em;
  margin: 35px 0;
`;

export const Thumbs = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  margin: 0 auto;

  img {
    max-width: calc(100% / 3 - 10px);
    margin-bottom: 10px;
    height: auto;

    @media (max-width: 800px) {
      max-width: calc(100% / 2 - 10px);
    }
  }
`;
