import styled, { createGlobalStyle, css } from 'styled-components';

import HalyardDisplayBlack from '../static/HalyardDisplayBlack.woff';
import HalyardDisplayBlack2 from '../static/HalyardDisplayBlack.woff2';
import DidotBold from '../static/Didot-Bold.woff';
import DidotBold2 from '../static/Didot-Bold.woff2';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  @font-face {
    font-family: 'Halyard';
    src: url(${HalyardDisplayBlack2}) format('woff2'),
        url(${HalyardDisplayBlack}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Didot';
    src: url(${DidotBold2}) format('woff2'),
        url(${DidotBold}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }
`;

export const Col = styled.div`
  width: 50%;

  @media (max-width: 700px) {
    width: 100%;
    ${({ clem }) =>
      clem &&
      css`
        display: none;
      `};
  }
`;
