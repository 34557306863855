import React from 'react';
import { Container, Logo, FinePrint } from './footer.css';

const Footer = () => (
  <Container>
    <Logo />

    <FinePrint>*contest not affiliated with or sponsored by Fender</FinePrint>
  </Container>
);

export default Footer;
