import styled, { css } from 'styled-components';
// import { gold, grey } from '../../constants/theme';

export const CloseBtn = styled.button`
  background: none;
  position: absolute;
  right: 10px;
  top: 15px;
  border: none;
  cursor: pointer;

  &:hover {
    svg #shape {
      fill: #fff;
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ thanks }) =>
    thanks &&
    css`
      height: 400px;
      justify-content: center;
    `}

  button {
    width: 80%;
    background: #fff;
    color: #000;
    border: none;
    padding: 10px;
    font-family: 'Halyard';
    text-transform: uppercase;
    font-size: 1em;
    margin-top: 10px;
    letter-spacing: 0.02em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  .formGroup {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 0 18px;
    & > label {
      width: 100%;
      text-transform: uppercase;
      font-size: 0.8em;
      font-weight: 600;

      div {
        margin-bottom: 5px;
      }
    }

    input {
      width: 100%;
      padding: 10px;
      font-size: 14px;
      border: none;
    }

    &.inlineCheckbox {
      flex-direction: row;
    }

    &.inputCheck {
      margin-bottom: 10px;

      label {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        div {
          color: #fff;
        }

        a {
          color: #fff;
          text-decoration: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);

          &:hover {
            color: #000;
            border-bottom: 1px solid rgba(255, 255, 255, 1);
          }
        }

        input {
          width: auto;
          margin-right: 10px;
        }
      }
    }
  }

  .formGroup--half {
    margin: 0 0 18px;
    & > label {
      width: calc(50% - 5px);
    }
  }
`;

export const FormHeading = styled.div`
  font-family: 'Halyard';
  text-transform: uppercase;
  font-size: 2.5em;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  color: #000;

  span {
    color: #fff;
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;
