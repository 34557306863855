import styled from 'styled-components';
import { gold, grey } from '../../constants/theme';
import ShreddyGlazerr from '../../../static/shreddy-glazerr.png';

export const Container = styled.section`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 30px 20px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  display: block;
  margin-top: 10px;
  background: transparent url(${ShreddyGlazerr}) no-repeat;
  background-size: contain;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-bottom: 20.01763668%;
  }
`;

export const ContestName = styled.h2`
  display: block;
  font-family: 'Didot';
  text-transform: uppercase;
  line-height: 0.9;
  color: ${gold};
  font-size: 5.4em;

  @media (max-width: 950px) {
    font-size: 8.85vw;
  }

  @media (max-width: 700px) {
    font-size: 16.4vw;
  }
`;

export const Details = styled.p`
  color: #524f54;
  font-size: 1em;
  line-height: 1.45;
  margin: 20px 0;

  strong {
    color: #000;
  }

  @media (max-width: 950px) {
    font-size: 1.8vw;
  }

  @media (max-width: 700px) {
    font-size: 3.4vw;
  }
`;

export const Copy = styled.div`
  text-align: center;
  margin-bottom: 60px;

  .gifting {
    font-size: 0.75em;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #a9a9a9;
  }
`;

export const BtnsGroup = styled.div`
  display: flex;

  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

export const BtnLink = styled.a`
  display: block;
  text-decoration: none;
  width: 100%;
  background-color: ${grey};
  margin: 0 3px;
  color: #fff;
  text-transform: uppercase;
  line-height: 3.3;
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  @media (max-width: 920px) {
    &:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const Btn = styled.button`
  display: block;
  width: 100%;
  background-color: ${grey};
  margin: 0 3px;
  color: #fff;
  text-transform: uppercase;
  line-height: 3;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  @media (max-width: 920px) {
    &:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const ClemWrapper = styled.div`
  width: 100%;
  position: relative;

  img {
    width: 100%;
    max-width: 500px;
    position: absolute;
  }
`;
