import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Promo from '../components/promo';
import IGBlock from '../components/instagram';
import Footer from '../components/footer';

class Index extends React.Component {
  componentDidMount() {
    window.location.replace('http://cherry-glazerr.com');
  }

  render() {
    return (
      <Layout>
        <Hero />
        <Promo />
        <IGBlock />
        <Footer />
      </Layout>
    );
  }
}

export default Index;
