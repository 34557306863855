import React from 'react';
import { Container, Title, Thumbs } from './instagram.css';

const IGBlock = () => (
  <Container>
    <Title>#cherryglazed</Title>

    <Thumbs />
  </Container>
);

export default IGBlock;
